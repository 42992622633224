const API_URL = "https://api.collegepass.org/advisingApi/v1/";
const API_URL_V2 = "https://api.collegepass.org/";

// const API_URL_V2 = "http://localhost:4000/";
// const API_URL = "http://localhost:4000/advisingApi/v1/";

export const APIGetUserAdvisors = `${API_URL_V2}advising/v2/utils/getadvisors/`;

export const APIstoreUserTask = `${API_URL}storeUsersTask`;

export const APIgetUserTask = `${API_URL}getUserTasks/`;

export const APIMarkAsDone = `${API_URL}markTodoCompleted/`;

export const APIUpdateVisibility = `${API_URL}updateVisibility/`;

export const APIRemoveTask = `${API_URL}removeUsersTask/`;

export const APIGetUserConnection = `${API_URL}getUsersConnections/`;

export const APIUpdateUserConnection = `${API_URL}updateConnectionField/`;

export const APIGetUserContext = `${API_URL}fetchAllContext/`;

export const APIStoreUserContext = `${API_URL}storeContext/`;

export const APIUpdateUserContext = `${API_URL}updateUserContext`;

export const APIGetShortlistedColleges = `${API_URL}getCollegeList/`;

export const getStudentGradeDataAPI = `${API_URL}gpa/getStudentGradeData/`;

export const getGPAValue = `${API_URL}gpa/getGPAValue/`;

export const getMeetingDetails = `${API_URL}meeting/getMeetingDetails/`;

export const StoreStudentGPA = `${API_URL}GPA/StoreStudentGPA`;

export const ClearStudentGPA = `${API_URL}GPA/ClearStudentGPA`; // added

export const getStudentGradeGPAData = `${API_URL}gpa/getStudentGradeGPAData/`;

export const storeShortlistedAPI = `${API_URL}storeShortlistedColleges/`;

export const getShortlistedColleges = `${API_URL}getShortListedColleges/`;

export const getDiscussionBoardMessages = `${API_URL}getMessages/`;

export const insertDiscussionBoardMessage = `${API_URL}storeMessage/`;

export const loadUserDetailsAPI = `${API_URL}getUserDetailsByTokenAdvising/`;

export const loginAPI = `${API_URL}auth/counsellorLogin/`;

export const studentLoginAPI = `https://api.collegepass.org/api/v1/auth/login`;

export const getSummerColleges = `${API_URL}getUserSummerColleges/`;

export const setSummerShortlist = `${API_URL}shortlistSummerCollege/`;

export const APIGetUserProfileInfo = `${API_URL}getUserProfileData/`;

export const APIStoreUserPersonalInfo = `${API_URL}storeProfilePersonal/`;

export const APIStoreUserContactInfo = `${API_URL}storeContactPersonal/`;

export const APIGetCountry = `https://api.collegepass.org/api/v1/profile/GetCountry`;

export const APIStoreGeoLangProfile = `${API_URL}storeGeoLanguageProfile/`;

export const APIStoreHouseholdProfile = `${API_URL}storeHouseholdProfile/`;

export const APIStoreParentProfile = `${API_URL}storeParentProfile/`;

export const APIGetStateByCountryID = `https://api.collegepass.org/api/v1/profile/GetStateByCountryID/`;

export const APIGetCityByStateID = `https://api.collegepass.org/api/v1/profile/GetCityByStateID/`;

export const APIStoreAddressProfile = `${API_URL}storeAddressPersonal/`;

export const getExtracurricularDetails = `${API_URL}getExtracurricularData/`;

export const extracurricularMetric = `${API_URL}getExtracurricularMetricField/`;

export const storeExtraCurricular = `${API_URL}storeExtracurricular/`;

export const getUserExtraCurricular = `${API_URL}getUserExtracurricular/`;

export const APIGetAcademicDetails = `${API_URL}getUserAcademicData/`;

export const APIGetActualAchievementDetails = `${API_URL}getActualAchievementData/`;

export const APIGetExtracurricularDetails = `${API_URL}getUserExtracurricular/`;

export const APIGetStudentProfileDetails = `${API_URL}getStudentProfileRecommendation/`;

export const APIGetShortlistedSummerColleges = `${API_URL}getShortlistedSummerColleges/`;

export const APIStoreGPATargetTier = `${API_URL}storeTargetTierGPA/`;

export const APIStoreGPANote = `${API_URL}storeNotesGPA/`;

export const APIStoreTargetTier = `${API_URL}storeTargetTier/`;

export const APIStoreNote = `${API_URL}storeNote/`;

export const APIGetUserCollegeEssays = `${API_URL}getCollegeEssays/`;

export const APIGetUserAwards = `${API_URL}getUserAwards/`;

export const APIGetUserStories = `${API_URL}getUserStoryTelling/`;

export const APIGetUserHomeDetails = `${API_URL}getHomeUserDetails/`;

export const APIStoreUserAwards = `${API_URL}storeAwards/`;

export const APIGetUserAwardsForm = `${API_URL}getUserAwards/`;

export const APIDeleteUserAward = `${API_URL}deleteUserAward/`;

export const APIStoreUserStory = `${API_URL}storeStory/`;

export const APIGetUserStoryForm = `${API_URL}getUserStoryTelling/`;

export const APIDeleteUserStory = `${API_URL}deleteUserStory/`;

export const APIUpdateUserMajor = `${API_URL}storeSpecialization/`;

export const APIStoreSchoolSupport = `${API_URL}storeSchoolSupport/`;

export const APIGetSchoolSupport = `${API_URL}getSchoolSupport/`;

export const APIDeleteSchoolSupport = `${API_URL}deleteSchoolSupport/`;

export const APIGetUserDetailsShortlisting = `${API_URL}getUserDetailsForShortlisting/`;

export const APIGetPastMeetings = `${API_URL}getPreviousRecording/`;

export const APIGetRecordingUrl = `${API_URL}getMeetingRecordingURL/`;

export const APIUpdateStudentAction = `${API_URL}updateStudentAction/`;

export const APIUpdateEditorAction = `${API_URL}updateEditorAction/`;

export const APIUpdateEssayRound = `${API_URL}updateEssayRound/`;

export const APIStoreScore = `${API_URL}standard_tests/storeTestScores/`;

export const APIUpdateScore = `${API_URL}standard_tests/updateTestScores/`;

export const APIgetZoomSignature = `https://api.collegepass.org/api/v1/events/getZoomSignature/`;

export const APIGetUserReadingList = `${API_URL}getUserReadingList/`;

export const APIStoreUserReadingList = `${API_URL}storeReadingList/`;

export const APIDeleteUserReadingList = `${API_URL}deleteReadingList/`;

export const APIGetSummerCollegesEssays = `${API_URL}getSummerCollegeEssays/`;

export const APIUpdateSummerStudentAction = `${API_URL}updateSummerStudentAction/`;

export const APIUpdateSummerEditorAction = `${API_URL}updateSummerEditorAction/`;

export const APIUpdateSummerRound = `${API_URL}updateSummerEssayRound/`;

export const APIStoreSummerPlan = `${API_URL}storeSummerPlan/`;

export const APIDeleteSummerPlan = `${API_URL}deleteSummerPlan/`;

export const APIGetSummerPlan = `${API_URL}getSummerPlan/`;

export const getCounsellorStudentsUrl = `${API_URL}meeting/getCounsellorStudents/`;

export const APIGetUserProfilePic = `${API_URL}profile/getUserProfilePicture/`;

export const APIStoreUserProfilePic = `${API_URL}profile/storeProfilePicture/`;

export const getUserDetailsByID = `${API_URL}getUserDetailsByID/`;

export const APIUKCollegeRequiredData = `${API_URL}getUKRequiredData/`;

export const APIGetUKColleges = `${API_URL}getUKCollegeList/`;

export const APIGetSchoolGradeData = `${API_URL}getUKGradeData/`;

export const getPrimePlusUsers = `${API_URL}getPrimePlusUsers`;

export const updatePrimePlusUser = `${API_URL}updatePrimePlusUser`;

export const getCPUsers = `${API_URL}getCPUsers`;

export const APIGetUserShortlistedCollegesUK = `${API_URL}getUKShortListCollege/`;

export const getCPPrimePlusPlans = `${API_URL}getCPPrimePlusPlans`;

export const APIGetDiscussionMemberList = `${API_URL}getDiscussionBoardMember/`;

export const APIStoreCollegeDocId = `${API_URL}updateCollegeDocId/`;

export const APIStoreSummerCollegeDocId = `${API_URL}updateSummerDocId/`;

export const APIGetApplicationUserEssays = `${API_URL}getApplicationCollegeEssays/`;

export const APIUpdateApplicationUserStudentAction = `${API_URL}updateApplicationStudentAction/`;

export const APIUpdateApplicationUserEditorAction = `${API_URL}updateApplicationEditorAction/`;

export const APIUpdateApplicationRound = `${API_URL}updateApplicationEssayRound/`;

export const APIUpdateApplicationDocId = `${API_URL}updateApplicationDocId/`;

export const APIInsertApplicationEssay = `${API_URL}insertUserApplicationEssay/`;

export const APIUpdateUKCollegeCategory = `${API_URL}updateUKCollegeCategory/`;

export const getUserAlerts = `${API_URL}getUserAlerts`;

export const markAlertRead = `${API_URL}markAlertRead/`;

export const storeAlert = `${API_URL}storeAlert/`;

export const getCanadaShortListCollege = `${API_URL}getCanadaShortListCollege/`;

export const getCanadaCollegeList = `${API_URL}getCanadaCollegeList/`;

export const shortlistCanadaCollegeList = `${API_URL}shortlistCanadaCollegeList`;

export const updateCanadaCollegeCategory = `${API_URL}updateCanadaCollegeCategory/`;

export const shortlistUKCollegeList = `${API_URL}shortlistUKCollegeList`;

export const getIntegratedMedCollegeList = `${API_URL_V2}advising/v2/shortlisting/get-integrated-med-us-colleges`;

export const getIntegratedMedShortListCollege = `${API_URL_V2}advising/v2/shortlisting/shortlisted-med-us-colleges/`;

export const shortlistIntegratedMedCollegeList = `${API_URL_V2}advising/v2/shortlisting/integrated-med-us-colleges`;

export const APIGetCanadaSchoolGradeData = `${API_URL}getCanadaGradeData/`;

export const APIStoreTestData = `${API_URL}standard_tests/storeTestScores/`;

export const APIGetMyMeetings = `${API_URL}meeting/getMyMeetings/`;

export const APIGetCounsellorMeeting = `${API_URL}meeting/getCounsellorStudents/`;

export const APIScheduleMeeting = `${API_URL}meeting/scheduleMeeting/`;

export const APIDeleteMeeting = `${API_URL}meeting/deleteScheduledMeeting/`;

export const APIGetStudentTestData = `${API_URL}standard_tests/getStudentTestData/`;

export const APIGetZOOMData = `${API_URL}getCounsellorZoomData/`;

export const APIUpdateShortlistingStatus = `${API_URL}changeShortlistingLockStatus/`;

export const APIGetShortlistingStatus = `${API_URL}getShortlistedStatus/`;

export const APIgetAPACCollegeList = `${API_URL}getAPACCollegeList/`;

export const APIgetResources = `${API_URL}getResources/`;

export const shortlistAPACCollegeList = `${API_URL}shortlistAPACCollegeList`;

export const updateAPACCollegeCategory = `${API_URL}updateAPACCollegeCategory/`;

export const getAPACShortListCollege = `${API_URL}getAPACShortListCollege/`;

export const shortlistResource = `${API_URL}shortlistResource/`;

export const getUserResources = `${API_URL}getUserResources/`;

export const getEuropeCollegeList = `${API_URL}getEuropeCollegeList/`;

export const getEuropeShortListCollege = `${API_URL}getEuropeShortListCollege/`;

export const shortlistEuropeCollegeList = `${API_URL}shortlistEuropeCollegeList`;

export const updateEuropeCollegeCategory = `${API_URL}updateEuropeCollegeCategory/`;

export const getUserPremiumValues = `${API_URL}getUserPremiumValues/`;

export const APIupdatetier = `${API_URL}updatetier/`;

export const APIUpdateSummerPlantier = `${API_URL}updateSummerPlanTier/`;

export const APIgetMembersListMeeting = `${API_URL_V2}advising/v2/meeting/meeting-members`;

export const APIgetResourcesV2 = `${API_URL_V2}advising/v2/resources/getResources/`;
export const APIgetUserResourcesV2 = `${API_URL_V2}advising/v2/resources/getUserResource/`;

export const APIupdateResourcesYearMonthV2 = `${API_URL_V2}advising/v2/resources/updateMonthYear/`;
export const APIupdateSCYearMonthV2 = `${API_URL_V2}advising/v2/resources/update-sc-month-year/`;

export const APIsendWelcomeEmail = `${API_URL_V2}advising/v2/utils/welcome-message/`;

export const APIGetAdmissionsUsers = `${API_URL_V2}advising/v2/manager/get-admissions-users`;

export const APISyncShortlistUsers = `${API_URL_V2}advising/v2/shortlisting/colleges-sync`;

export const APISyncDELShortlistUsers = `${API_URL_V2}advising/v2/shortlisting/colleges-sync`;

export const APISyncGETShortlistUsers = `${API_URL_V2}advising/v2/shortlisting/colleges-sync`;

export const APIEC_Delete = `${API_URL_V2}advising/v2/extracurricular`;

export const GET_AID = `${API_URL_V2}advising/v2/financialaid/getaid`;

export const shortlistAid = `${API_URL_V2}advising/v2/financialaid/shortlistAid/`;

export const APIgetUserAidV2 = `${API_URL_V2}advising/v2/financialaid/getUserAid/`;

// ==========================================================================================
export const getShortlistedCountries = `${API_URL}getShortlistedCountries/`;

export const APIGetAdmissionsUsersV1 = `${API_URL_V2}advising/v2/manager/get-admissions-users-pagination`;

export const APIGetAdmissionsUsersCount = `${API_URL_V2}advising/v2/manager/get-admissions-users-count`;

export const APIGetAdmissionsUsersSearch = `${API_URL_V2}advising/v2/manager/get-admissions-users-search`;

export const APIGetAdmissionsUsersSearchByEmail = `${API_URL_V2}advising/v2/manager/get-admissions-users-emailSearch`;

export const APIGetAdmissionsUsersSearchByName = `${API_URL_V2}advising/v2/manager/get-admissions-users-nameSearch`;

export const APIgetUserResourcesV3 = `${API_URL_V2}advising/v2/resources/getUserResourceV1/`;

export const APIUpdateStudentTestData = `${API_URL}standard_tests/updateStandardizedTestTargetDate/`;

export const APIDeleteUserContext = `${API_URL}deleteUserContext`;

export const APIStoreUserContextV1 = `${API_URL}storeContextV1/`;

export const APIGetUserContextV1 = `${API_URL}fetchAllContextV1/`;

export const updateMeetingCalendar = `${API_URL}meeting/updateMeetingCalendar/`;

export const APIstoreUserTaskV1 = `${API_URL}storeUsersTaskV1`;

export const APIstoreUserTaskV2 = `${API_URL}storeUsersTaskV2`;

export const APIUpdateTaskCalendar = `${API_URL}updateTaskCalendar`;

export const APIgetUserTaskV1 = `${API_URL}getUserTasksV1/`;

export const APIgetMembersListTask = `${API_URL_V2}advising/v2/meeting/task-members`;

export const APIUpdateStudentTestCalendar = `${API_URL}standard_tests/updateStandardizedTestCalendar/`;

export const APIUpdateResourceRegistrationDate = `${API_URL_V2}advising/v2/resources/updateRegistrationDateResources/`;

export const APIUpdateResourceCalendar = `${API_URL_V2}advising/v2/resources/updateResourcesCalendar/`;

export const APIUpdateShortlistedSummerCollegeRegistrationDate = `${API_URL}updateShortlistedSummerCollegeRegistrationDate`;

export const APIUpdateShortlistedSummerCollegeCalendar = `${API_URL}updateShortlistedSummerCollegeCalendar/`;

export const APIUpdateShortlistAidRegistrationDate = `${API_URL_V2}advising/v2/financialaid/updateShortlistAidRegistrationDate/`;

export const APIUpdateShortlistAidCalendar = `${API_URL_V2}advising/v2/financialaid/updateShortlistAidCalendar/`;

export const APIgetUserAidV3 = `${API_URL_V2}advising/v2/financialaid/getUserAidV1/`;

export const APIUpdateStudentPitchDetails = `${API_URL}updateStudentPitchDetails/`;

export const APIUpdateExtracurricularSpike = `${API_URL}updateExtracurricularSpike/`;

export const APIUpdateTaskProgress = `${API_URL}updateTaskProgress`;

export const APIUpdateTaskDescription = `${API_URL}updateTaskDescription`;

export const APIDeleteTask = `${API_URL}deleteTask`;

export const APIGetCounsellorProfileById = `${API_URL}getCounsellorProfileById`;

export const APIDeleteDiscussionBoardMessage = `${API_URL}deleteMessage/`;

export const APIStoreResourceNote = `${API_URL_V2}advising/v2/resources/storeResourceNote/`;

export const APIUpdateStudentSchoolName = `${API_URL}updateStudentSchoolName/`;

export const APIUpdateStudentCurriculum = `${API_URL}updateStudentCurriculum/`;

export const APIDeleteTestData = `${API_URL}standard_tests/deleteTest/`;

export const APIUpdateOrder = `${API_URL_V2}advising/v2/extracurricular/updateOrder`;

export const APIUpdateStudentSchoolYear = `${API_URL}updateStudentSchoolYear/`;

export const APIUpdateShortlistedSummerCollegeDeadline = `${API_URL}updateShortlistedSummerCollegeDeadline/`;

export const getCounsellorStudentsUrlV1 = `${API_URL}meeting/getCounsellorStudentsV1/`;

export const getCounsellorStudentsUrlV1ByName = `${API_URL}meeting/getCounsellorStudentsV1ByName/`;

export const getCounsellorStudentsCountV1 = `${API_URL}meeting/getCounsellorStudentsCountV1/`;

export const APIUpdateActivityProgress = `${API_URL_V2}advising/v2/resources/updateActivityProgress/`;

export const APIGetActivityProgress = `${API_URL_V2}advising/v2/resources/getActivityProgress/`;

export const APIGetPastMeetingsV1 = `${API_URL}getPreviousRecordingV1/`;

export const APIUpdateParentMeetingStatus = `${API_URL}updateParentMeetingStatus/`;

export const APIupdateAcademicYearMonth = `${API_URL_V2}advising/v2/resources/updateMonthYearAcademic`;

export const APIUpdateUserCollegeEssays = `${API_URL}updateUserCollegeEssays/`;

export const APIGetMyMeetingsV1 = `${API_URL}meeting/getMyMeetingsV1/`;

export const APIUpdateProfileDetails = `${API_URL}updateProfileDetails/`;

export const APIScheduleMeetingV1 = `${API_URL}meeting/scheduleMeetingV1/`;

export const APIGetAdmissionsUsersByFilter = `${API_URL_V2}advising/v2/manager/get-admissions-users-filter`;

export const APIGetPrimePlusUsersAdvisorAssessment = `${API_URL_V2}advising/v2/manager/get-admissions-users-advisor-assessment/`;

export const APIGetUserProfileInfoV1 = `${API_URL}getUserProfileDataV1/`;

export const getCounsellorStudentsByGradeUrlV1 = `${API_URL}meeting/getCounsellorStudentsByGradeV1/`;

export const APISendDiscussionEmail = `${API_URL_V2}advising/v2/utils/discussion/`;

export const APIGetDiscussionEmails = `${API_URL_V2}advising/v2/utils/getDiscussionEmails/`;

export const APIDiscussionEmailById = `${API_URL_V2}advising/v2/utils/discussionEmailById/`;

// manager panel apis

export const APIGetAdmissionsUsersV2 = `${API_URL_V2}advising/v2/manager/get-admissions-users-paginationV2`;

export const APIGetAdmissionsUsersCountV2 = `${API_URL_V2}advising/v2/manager/get-admissions-users-countV2`;

export const APIGetAdmissionsUsersSearchV2 = `${API_URL_V2}advising/v2/manager/get-admissions-users-searchV2`;

export const APIGetAdmissionsUsersSearchByEmailV2 = `${API_URL_V2}advising/v2/manager/get-admissions-users-emailSearchV2`;

export const APIGetAdmissionsUsersSearchByNameV2 = `${API_URL_V2}advising/v2/manager/get-admissions-users-nameSearchV2`;

export const APIGetAdmissionsUsersByFilterV2 = `${API_URL_V2}advising/v2/manager/get-admissions-users-filterV2`;
// end

export const APIUpdateActiveStatus = `${API_URL_V2}advising/v2/manager/updateActiveStatus`;

export const APIStoreTestScoreWithDate = `${API_URL_V2}advising/v2/resources/storeTestScoreWithDate/`;

export const APIStoreTestScoreWithMonthAndYear = `${API_URL_V2}advising/v2/resources/storeTestScoreWithMonthAndYear/`;

export const APIDeleteTestScoreWithZero = `${API_URL_V2}advising/v2/resources/deleteTestScoreWithZero/`;

export const APIGetAdvisorUsersV1 = `${API_URL_V2}advising/v2/manager/get-advisor-users-pagination`;

export const APIGetAdvisorUsersCount = `${API_URL_V2}advising/v2/manager/get-advisor-users-count`;

export const APIGetAdvisorUsersSearch = `${API_URL_V2}advising/v2/manager/get-advisor-users-search`;

export const APIGetAdvisorUsersSearchByEmail = `${API_URL_V2}advising/v2/manager/get-advisor-users-emailSearch`;

export const APIGetAdvisorUsersSearchByName = `${API_URL_V2}advising/v2/manager/get-advisor-users-nameSearch`;

export const APIGetAdvisorUsersByFilter = `${API_URL_V2}advising/v2/manager/get-advisor-users-filter`;

export const APIStoreNoteV1 = `${API_URL_V2}advising/v2/extracurricular/storeNoteV1/`;

export const APIGetExtracurricularDetailsV1 = `${API_URL_V2}advising/v2/extracurricular/getUserExtracurricularV1/`;

export const APIGetAcademicDetailsV1 = `${API_URL}getUserAcademicDataV1/`;

export const APIGetShortlistedSummerCollegesV1 = `${API_URL}getShortlistedSummerCollegesV1/`;

export const APIGetUserAwardsV1 = `${API_URL}getUserAwardsV1/`;

export const APIStoreGPANoteV1 = `${API_URL}storeNotesGPAV1/`;

export const APIStoreResourceNoteV1 = `${API_URL_V2}advising/v2/resources/storeResourceNoteV1/`;

export const APIgetUserResourcesV4 = `${API_URL_V2}advising/v2/resources/getUserResourceV2/`;

export const APIScheduleMeetingV2 = `${API_URL_V2}advising/v2/meeting/scheduleMeetingV2`;

export const APIGetAdvisorUsersByFilterV1 = `${API_URL_V2}advising/v2/manager/get-advisor-users-filterV1`;

export const APIGetAdmissionsUsersByFilterV3 = `${API_URL_V2}advising/v2/manager/get-admissions-users-filterV3`;

export const APIGetAdmissionsUsersV3 = `${API_URL_V2}advising/v2/manager/get-admissions-users-paginationV3`;

export const APIGetAdvisorUsersV2 = `${API_URL_V2}advising/v2/manager/get-advisor-users-paginationV1`;

export const APIGetAdvisorUsersByFilterV2 = `${API_URL_V2}advising/v2/manager/get-advisor-users-filterV2`;

export const APIGetAdmissionsUsersByFilterV4 = `${API_URL_V2}advising/v2/manager/get-admissions-users-filterV4`;

export const APIStoreCallLog = `${API_URL_V2}advising/v2/meeting/storeLog`;

export const APIGetCallLogs = `${API_URL_V2}advising/v2/meeting/getCallLogs/`;

export const APIUpdateCallParentParticipateStatus = `${API_URL_V2}advising/v2/meeting/updateParentParticipateStatus/`;

export const APIDeleteCallLog = `${API_URL_V2}advising/v2/meeting/deleteCallLog/`;

export const APIGetCounsellorMeetingStudents = `${API_URL_V2}advising/v2/meeting/getCounsellorMeetingStudents/`;

export const APIGetAdvisorUsersV3 = `${API_URL_V2}advising/v2/manager/get-advisor-users-paginationV2`;

export const APIDeleteMeetingV1 = `${API_URL_V2}advising/v2/meeting/deleteScheduledMeetingV1/`;

// Advisor Essay APIs
export const APIGetAdvisorUsersEssays = `${API_URL_V2}advising/v2/manager/get-advisor-essays-pagination`;

export const APIGetAdvisorUsersEssaysSearch = `${API_URL_V2}advising/v2/manager/get-advisor-essays-search`;

export const APIGetAdvisorUsersEssaysSearchByEmail = `${API_URL_V2}advising/v2/manager/get-advisor-essays-emailSearch`;

export const APIGetAdvisorUsersEssaysSearchByName = `${API_URL_V2}advising/v2/manager/get-advisor-essays-nameSearch`;

export const APIGetAdvisorUsersEssaysByFilter = `${API_URL_V2}advising/v2/manager/get-advisor-essays-filter`;

export const APIGetAdvisorUsersEssaysCount = `${API_URL_V2}advising/v2/manager/get-advisor-essays-count`;

// Manager Essay APIs
export const APIGetAdmissionUsersEssays = `${API_URL_V2}advising/v2/manager/get-admissions-essays-pagination`;

export const APIGetAdmissionUsersEssaysSearch = `${API_URL_V2}advising/v2/manager/get-admissions-essays-search`;

export const APIGetAdmissionUsersEssaysSearchByEmail = `${API_URL_V2}advising/v2/manager/get-admissions-essays-emailSearch`;

export const APIGetAdmissionUsersEssaysSearchByName = `${API_URL_V2}advising/v2/manager/get-admissions-essays-nameSearch`;

export const APIGetAdmissionUsersEssaysByFilter = `${API_URL_V2}advising/v2/manager/get-admissions-essays-filter`;

export const APIUpdateUserCollegeEssayCompletionDate = `${API_URL}updateCollegeEssayCompletionDate/`;

export const APIUpdateApplicationUserCollegeEssayCompletionDate = `${API_URL}updateApplicationCollegeEssayCompletionDate/`;

export const updatePrimePlusUserV2 = `${API_URL}updatePrimePlusUserV2`;

export const APIGetDiscussionMemberListV2 = `${API_URL}getDiscussionBoardMemberV2/`;

// Online Course CMS API
export const APIPostReviewOnlineCourse = `${API_URL_V2}api/cms/reviewOnlinecourse`;

// NGO CMS API
export const APIPostReviewNGO = `${API_URL_V2}api/cms/reviewNGO`;

// Summer Program CMS API
export const APIPostReviewSummerCollege = `${API_URL_V2}api/cms/reviewSummerCollege`;

export const APIDeleteApplicationEssay = `${API_URL}deleteUserApplicationEssay/`;

export const APIStoreResearchFocus = `${API_URL_V2}advising/v2/resources/storeResearchFocus`;

export const APIGetResearchFocus = `${API_URL_V2}advising/v2/resources/getResearchFocus/`;

export const APIScheduleGoogleMeet = `${API_URL_V2}advising/v2/meeting/scheduleGoogleMeet`;

export const APIDeleteGoogleMeet = `${API_URL_V2}advising/v2/meeting/deleteGoogleMeet/`;

export const APIStoreCounsellorGoogleMeetCredentials = `${API_URL_V2}advising/v2/meeting/storeCounsellorGoogleMeetingCredentials/`;

export const APIStoreCounsellorEmailAccessCredentials = `${API_URL_V2}advising/v2/utils/storeCounsellorEmailAccessCredentials/`;

// Tutoring APIs
export const APITutorAdminLogin = `${API_URL_V2}tutoring/tutorAdminLogin`;

export const APITutoringStudentLogin = `${API_URL_V2}tutoring/studentLogin`;

export const APIOnboardStudent = `${API_URL_V2}tutoring/onboardStudent`; // Post

export const APIAddCourse = `${API_URL_V2}tutoring/addCourse`; // Post

export const APIGetCourses = `${API_URL_V2}tutoring/getCourses`; // Get

export const APIGetCourseById = `${API_URL_V2}tutoring/getCourse/:id`; // Get

export const APICreateJob = `${API_URL_V2}tutoring/createJob`; // Post

export const APIGetJobs = `${API_URL_V2}tutoring/getJobs`; // Get

export const APIGetJobById = `${API_URL_V2}tutoring/getJobById`; // Get

export const APIGetJobsByTutor = `${API_URL_V2}tutoring/getJobsByTutor/`; // Get

export const APIGetJobsByStudent = `${API_URL_V2}tutoring/getJobsByStudent/`; // Get

export const APICreateClass = `${API_URL_V2}tutoring/createClass`; // Post

export const APIDeleteClass = `${API_URL_V2}tutoring/deleteClass/`; // Delete

export const APIGetUpcomingClasses = `${API_URL_V2}tutoring/getUpcomingClasses`; // Get

export const APIGetPreviousClasses = `${API_URL_V2}tutoring/getPreviousClasses`; // Get

export const APIGetTutorUpcomingClasses = `${API_URL_V2}tutoring/getTutorUpcomingClasses/`; // Get

export const APIGetTutorPreviousClasses = `${API_URL_V2}tutoring/getTutorPreviousClasses/`; // Get

export const APIGetStudentUpcomingClasses = `${API_URL_V2}tutoring/getStudentUpcomingClasses/`; // Get

export const APIGetStudentPreviousClasses = `${API_URL_V2}tutoring/getStudentPreviousClasses/`; // Get

export const APICreateTutor = `${API_URL_V2}tutoring/createTutor`; // Post

export const APIGetTutoringStudents = `${API_URL_V2}tutoring/getStudents`; // Get

export const APIGetTutoringStudentById = `${API_URL_V2}tutoring/getStudentById/`; // Get

export const APIGetTutoringStudentsByTutor = `${API_URL_V2}tutoring/getStudentsByTutor/`; // Get

export const APIGetTutors = `${API_URL_V2}tutoring/getTutors`; // Get

export const APIGetTutorById = `${API_URL_V2}tutoring/getTutorById/`; // Get

export const APIGetTutoringTutorsByStudent = `${API_URL_V2}tutoring/getTutorsByStudent/`; // Get

export const APIGetTutoringSubjects = `${API_URL_V2}tutoring/getSubjects`; // Get

export const APIgetAllCitiesByName = `${API_URL_V2}api/v2/utils/citiesByName/`;

export const APIgetCitiById = `${API_URL_V2}api/v2/utils/citiById/`;

export const APIGetClassRecordingURL = `${API_URL_V2}tutoring/getClassRecordingURL/`; // Get

export const APIGetClassStartURL = `${API_URL_V2}tutoring/getClassStartURL/`; // Get

export const APIJobCountForStudentWithSameCourse = `${API_URL_V2}tutoring/getJobCount/`; // Get

export const APIGetScheduledClassCount = `${API_URL_V2}tutoring/getScheduledClassCount/`; // Get

export const APIGetUpcomingClassesByJob = `${API_URL_V2}tutoring/getUpcomingClassesByJob/`; // Get

export const APIGetPreviousClassesByJob = `${API_URL_V2}tutoring/getPreviousClassesByJob/`; // Get

export const APIGetTutorsForFilter = `${API_URL_V2}tutoring/getTutorsForFilter`; // Get

export const APIGetStudentsForFilter = `${API_URL_V2}tutoring/getStudentsForFilter`; // Get

export const APIGetStudentInformation = `${API_URL_V2}tutoring/getStudentInformation/`; // Get

export const APIUpdateStudentInformation = `${API_URL_V2}tutoring/updateStudentInformation`; // Patch

export const APICheckEmptySlot = `${API_URL_V2}tutoring/checkEmptySlot`; // Patch

export const APIDeactivateStudentTutoringAccount = `${API_URL_V2}tutoring/deactivateStudentTutoringAccount/`; // Delete

// Demo Class Endpoints:
export const APICreateDemoJob = `${API_URL_V2}tutoring/createDemoJob`; // Post

export const APIGetDemoJobs = `${API_URL_V2}tutoring/getDemoJobs`; // Get

export const APIGetDemoJobById = `${API_URL_V2}tutoring/getDemoJobById`; // Get

export const APIGetDemoJobsByTutor = `${API_URL_V2}tutoring/getDemoJobsByTutor/`; // Get

export const APIGetDemoJobsByStudent = `${API_URL_V2}tutoring/getDemoJobsByStudent/`; // Get

export const APICreateDemoClass = `${API_URL_V2}tutoring/createDemoClass`; // Post

export const APIDeleteDemoClass = `${API_URL_V2}tutoring/deleteDemoClass/`; // Delete

export const APIGetUpcomingDemoClasses = `${API_URL_V2}tutoring/getUpcomingDemoClasses`; // Get

export const APIGetPreviousDemoClasses = `${API_URL_V2}tutoring/getPreviousDemoClasses`; // Get

export const APIGetTutorUpcomingDemoClasses = `${API_URL_V2}tutoring/getTutorUpcomingDemoClasses/`; // Get

export const APIGetTutorPreviousDemoClasses = `${API_URL_V2}tutoring/getTutorPreviousDemoClasses/`; // Get

export const APIGetStudentUpcomingDemoClasses = `${API_URL_V2}tutoring/getStudentUpcomingDemoClasses/`; // Get

export const APIGetStudentPreviousDemoClasses = `${API_URL_V2}tutoring/getStudentPreviousDemoClasses/`; // Get

export const APIGetUpcomingDemoClassesByJob = `${API_URL_V2}tutoring/getUpcomingDemoClassesByJob/`; // Get

export const APIGetPreviousDemoClassesByJob = `${API_URL_V2}tutoring/getPreviousDemoClassesByJob/`; // Get

export const APIGetDemoClassStartURL = `${API_URL_V2}tutoring/getDemoClassStartURL/`; // Get

export const APIStoreClassNote = `${API_URL_V2}tutoring/storeClassNote`;

export const APIRecordConceptBoardInteraction = `${API_URL_V2}tutoring/recordConceptBoardInteraction`;

export const APICreateTutorV2 = `${API_URL_V2}tutoring/createTutorV2`; // Post

export const APICreateJobV2 = `${API_URL_V2}tutoring/createJobV2`; // Post

export const APITutorReport = `${API_URL_V2}tutoring/getTutorReport`; // Get

export const APITutorReportV2 = `${API_URL_V2}tutoring/getTutorReportV2`; // Get

export const APIStudentReport = `${API_URL_V2}tutoring/getStudentReport`; // Get

export const APIUpdateTutorInformation = `${API_URL_V2}tutoring/updateTutorInformation`; // Get

export const APIGetTutorInformation = `${API_URL_V2}tutoring/getTutorInformation`; // Get

// export const APIGetEssayTheme = `${API_URL_V2}advising/v2/resources/getEssayTheme/`;

export const APITutorReportV3 = `${API_URL_V2}tutoring/getTutorReportV3`; // Get

export const APIUpdateStudentAttendanceStatus = `${API_URL_V2}tutoring/updateStudentAttendanceStatus`; // Get

export const APIStudentReportV2 = `${API_URL_V2}tutoring/getStudentReportV2`; // Get

export const APIStudentReportV3 = `${API_URL_V2}tutoring/getStudentReportV3`; // Get

export const APICreateClassV2 = `${API_URL_V2}tutoring/createClassV2`; // Post

export const APIUploadHomeWork = `${API_URL_V2}tutoring/uploadHomeWork`; // Post

export const APIDeleteFile = `${API_URL_V2}tutoring/deleteFile`; // Delete

export const APIGetFileSignedUrl = `${API_URL_V2}tutoring/getFileSignedUrl`; // Get

export const APICreateRecurringClass = `${API_URL_V2}tutoring/createRecurringClass`; // Post
